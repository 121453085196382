*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

a,
a:visited {
  text-decoration: none !important; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; }

html {
  font-size: 62.5%; }
  @media (max-width: 596px) {
    html {
      font-size: 50%; } }

body {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif; }

.page-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: calc(100vh - 31rem); }

.header {
  display: flex;
  width: 100vw;
  background-color: #1a2238;
  justify-content: space-between;
  align-items: center;
  padding: 3rem; }
  @media (max-width: 596px) {
    .header {
      padding: 3rem 1.5rem; } }
  .header__left {
    display: flex;
    flex-direction: column; }
    .header__left--logo {
      font-size: 4rem;
      font-family: "Oswald", sans-serif;
      font-weight: 800;
      color: #f7f7f7; }
      .header__left--logo--blue {
        color: #9daaf2; }
      .header__left--logo--in {
        color: #9daaf2;
        font-size: 2.6rem;
        font-family: "Oswald", sans-serif;
        font-weight: 800;
        margin-top: -1.6rem;
        margin-left: 6rem; }
      .header__left--logo--services {
        color: #f7f7f7;
        font-size: 2.6rem;
        font-family: "Oswald", sans-serif;
        font-weight: 800;
        margin-top: -1.3rem;
        margin-left: 1.7rem; }
        .header__left--logo--services--blue {
          color: #9daaf2;
          margin-left: 0; }
  @media (max-width: 768px) {
    .header__center {
      visibility: hidden;
      width: 0;
      height: 0; } }
  .header__right {
    width: max-content;
    display: flex;
    flex-direction: column;
    font-size: 1.8rem; }
    .header__right--phone {
      display: flex;
      margin-right: 3rem;
      padding-bottom: 1rem; }
      .header__right--phone--icon {
        color: #ff6a3d;
        font-size: 2.2rem;
        margin-right: 2rem; }
      .header__right--phone--number {
        color: #f7f7f7;
        text-decoration: none;
        border: none; }
    .header__right--email {
      display: flex; }
      .header__right--email--address {
        color: #f7f7f7;
        text-decoration: none;
        border: none; }
      .header__right--email--icon {
        color: #ff6a3d;
        font-size: 2.2rem;
        margin-right: 2rem; }

.save {
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: #f7f7f7; }
  .save__title {
    font-size: 3rem;
    font-weight: 800; }
  .save__subtext {
    font-size: 1.4rem;
    text-align: center;
    width: 100%; }
    .save__subtext--link {
      font-size: 1.6rem;
      padding-top: 2rem;
      font-weight: 800;
      text-align: center;
      width: 100%;
      color: #ff6a3d; }
      .save__subtext--link:hover {
        color: #9daaf2;
        cursor: pointer; }
  .save__grid {
    width: 100%;
    padding-top: 2rem;
    padding-left: 4rem;
    padding-right: 4rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(60rem, 1fr));
    text-align: center; }
    @media (max-width: 596px) {
      .save__grid {
        width: auto; } }
    .save__grid--item {
      font-size: 2.5rem;
      padding: 0.6rem 0;
      overflow-x: hidden; }

.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 4rem;
  padding-bottom: 6rem; }
  .services__title {
    font-size: 3rem;
    font-weight: 800;
    padding-bottom: 3rem; }
  .services__neg-margin--bottom {
    padding-bottom: 0; }
  @media (max-width: 596px) {
    .services__neg-margin--right {
      margin-right: 1rem; } }
  .services__container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 52rem;
    height: 32rem;
    background-color: #1a2238;
    box-shadow: 0 0.6rem 1rem rgba(0, 0, 0, 0.2); }
    @media (max-width: 596px) {
      .services__container {
        width: 42.2rem;
        height: 27rem; } }
  .services__left {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem;
    height: 100%;
    justify-content: space-between;
    width: 50%;
    color: #f7f7f7; }
    @media (max-width: 596px) {
      .services__left {
        padding: 1rem 0rem 1rem 1.5rem; } }
    .services__left--name {
      display: flex;
      flex-direction: column;
      font-size: 2.5rem; }
    .services__left--title {
      font-size: 1.6rem; }
  .services__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    font-size: 1.6rem; }
    .services__list--item {
      overflow-x: hidden; }
  .services__contact {
    font-size: 1.6rem; }
  .services__right {
    width: 50%;
    margin-right: 1rem; }
    @media (max-width: 596px) {
      .services__right {
        margin-right: 0; } }
    .services__right--circle {
      height: 25rem;
      width: 25rem;
      border-radius: 100rem;
      object-fit: cover; }
      @media (max-width: 596px) {
        .services__right--circle {
          width: 20rem;
          height: 20rem; } }

.contact {
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7f7;
  padding-top: 3rem;
  padding-bottom: 4rem; }
  .contact__form {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(50rem, 1fr));
    text-align: center;
    padding-top: 2rem; }
    .contact__form--wrapper {
      width: 100%; }
      @media (max-width: 596px) {
        .contact__form--wrapper {
          width: auto; } }
    .contact__form--loader {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4rem; }
    .contact__form--button {
      display: block;
      height: 4rem;
      width: 18rem;
      border: none;
      background-color: #ff6a3d;
      color: #f7f7f7;
      border-radius: 10rem;
      font-size: 2rem;
      font-weight: 800;
      margin-left: auto;
      margin-right: auto;
      -webkit-appearance: none;
      cursor: pointer; }
      .contact__form--button:hover {
        background-color: #9daaf2; }
      .contact__form--button:disabled {
        background-color: #696969; }
    .contact__form--success {
      font-size: 3rem;
      font-weight: 800;
      color: #ff6a3d;
      text-align: center;
      width: 100%; }
    .contact__form--group {
      height: 8rem; }
    .contact__form--label {
      display: inline-flex;
      width: 9rem;
      margin-right: 2rem;
      font-size: 2rem; }
    .contact__form--input {
      border: none;
      border-bottom: 2px solid #000000;
      border-top: 2x solid #ffffff00;
      width: 30rem;
      outline: none;
      background-color: #f7f7f7;
      font-size: 2rem;
      transition: all 0.3s; }
      .contact__form--input:focus {
        border-bottom: 2px solid #ff6a3d; }

.footer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-color: #1a2238; }
  @media (max-width: 596px) {
    .footer__width {
      width: 26rem; } }
  .footer__right {
    width: max-content;
    display: flex;
    flex-direction: column;
    font-size: 1.8rem; }
  .footer__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 3rem;
    padding-right: 3rem;
    height: 24rem; }
    @media (max-width: 596px) {
      .footer__top {
        padding-left: 1.5rem;
        padding-right: 1.5rem; } }
  .footer__phone {
    display: flex;
    font-size: 1.8rem;
    color: #f7f7f7;
    transition: all 0.3s; }
    .footer__phone:hover {
      color: #9daaf2; }
    .footer__phone--left {
      width: 6rem; }
  .footer__left {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #f7f7f7;
    font-size: 1.6rem; }
    .footer__left--copyright {
      margin: 2rem 0 2rem 2rem; }
    .footer__left--site {
      margin: 0 0 2rem 2rem; }

.aaxel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right; }
  .aaxel__logo {
    display: flex;
    align-self: flex-end;
    width: 6rem;
    background-color: white; }
  .aaxel__addline {
    color: #f7f7f7; }
  .aaxel__addline--name {
    font-weight: 800; }

.carousel {
  position: relative;
  width: 100%;
  height: max-content;
  font-weight: 800; }
  .carousel__left {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-left: 10%; }
    @media (max-width: 992px) {
      .carousel__left {
        width: 100%;
        height: 45%;
        margin-left: 0;
        justify-content: flex-end;
        align-items: center; } }
    @media (max-width: 596px) {
      .carousel__left {
        height: 30%; } }
    .carousel__left--title {
      font-size: 5rem; }
      @media (max-width: 992px) {
        .carousel__left--title {
          font-size: 3rem; } }
    .carousel__left--subtitle {
      font-size: 8rem; }
      @media (max-width: 992px) {
        .carousel__left--subtitle {
          display: none; } }
    .carousel__left--tagline {
      font-size: 4rem;
      font-weight: 600; }
      @media (max-width: 992px) {
        .carousel__left--tagline {
          display: none; } }
    .carousel__left--story {
      font-size: 2rem;
      font-weight: 600;
      margin-top: 0.6rem; }
      @media (max-width: 992px) {
        .carousel__left--story {
          display: none; } }
  .carousel__right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    margin-right: 10%; }
    @media (max-width: 992px) {
      .carousel__right {
        width: 100%;
        height: 50%;
        margin-right: 0;
        margin-top: 2rem;
        justify-content: center;
        align-items: flex-start; } }
    .carousel__right--button {
      height: 4rem;
      width: 20rem;
      font-size: 2rem;
      color: #f7f7f7;
      background-color: #ff6a3d;
      border: 2px solid #f7f7f7;
      -webkit-appearance: none; }
    .carousel__right--phone {
      font-size: 2rem;
      text-align: center;
      margin-top: 2rem;
      color: #ffffff; }
  .carousel__image {
    z-index: -1; }
    .carousel__image--overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      color: #f7f7f7;
      background-color: rgba(26, 34, 56, 0.3);
      z-index: 20000000; }
    .carousel__image--wrapper {
      display: flex;
      justify-content: flex-start;
      text-align: left;
      align-items: center;
      width: 100%;
      height: 100%; }
      @media (max-width: 992px) {
        .carousel__image--wrapper {
          flex-direction: column; } }

.relationship {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  padding-bottom: 3rem;
  width: 100%; }
  .relationship__title {
    font-size: 3rem;
    font-weight: 800; }
  .relationship__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
    justify-items: center;
    align-content: center;
    width: 100vw; }
    @media (max-width: 1060px) {
      .relationship__container {
        grid-template-columns: 1fr; } }
  .relationship-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 36rem;
    width: 35rem; }
    .relationship-card__icon {
      font-size: 14rem; }
    .relationship-card__title {
      font-size: 3rem;
      font-weight: 800;
      color: #ff6a3d; }
    .relationship-card__subtitle {
      font-size: 2.5rem;
      font-weight: 400;
      color: #ff6a3d; }
    .relationship-card__text {
      font-size: 1.6rem;
      font-weight: 200;
      text-align: center;
      margin-top: 0.5rem; }

.testimonial {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding-top: 4rem;
  padding-bottom: 4rem;
  align-items: center;
  justify-content: center; }

.testimonial__body {
  position: relative;
  margin: auto;
  font-style: italic;
  display: flex;
  flex-direction: column;
  padding: 4rem 0;
  font-size: 2rem;
  width: 46rem;
  color: #f7f7f7; }

.testimonial__author {
  margin-top: 2rem;
  font-style: normal;
  font-weight: 600; }

.testimonial__title {
  position: relative;
  display: flex;
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 4rem; }
